<template>
  <v-container fluid class="ma-0 pa-0 clear-background">
      
      <!-- Header -->
          <v-layout row wrap class="primary-background" :class="{'center': $vuetify.breakpoint.xlOnly}">
            <div class="max-width-1200 ma-auto" :class="{'ml-0': $vuetify.breakpoint.mdOnly, 'ml-0': $vuetify.breakpoint.lgOnly, 'mx-12': $vuetify.breakpoint.xlOnly}" v-if="$vuetify.breakpoint.smAndUp">
              <v-layout row wrap mt-12 mr-12 :class="{'mx-12 px-12': $vuetify.breakpoint.xlOnly}">
                <v-flex v-if="$vuetify.breakpoint.mdAndUp" md5><v-img :src="building" width="400px"></v-img></v-flex>
                  <v-flex sm8 md5 :class="{'offset-sm2': $vuetify.breakpoint.smAndDown, 'offset-sm1': $vuetify.breakpoint.mdAndUp}">
                    <h3 v-if="drive" class="banner-title font-48 white--text" >{{ $ml.get('about_title1') }} </h3>
                    <h3 v-if="innovation" class="banner-title font-48 white--text" >{{ $ml.get('about_title2') }} </h3>
                    <h3 v-if="integrity" class="banner-title font-48 white--text" >{{ $ml.get('about_title3') }} </h3>
                    <h3 v-if="leadership" class="banner-title font-48 white--text" >{{ $ml.get('about_title4') }} </h3>
                    <p v-if="drive" class="avenir font-18 white--text mb-0">  {{ $ml.get('about_text1') }}</p>
                    <p v-if="innovation" class="avenir font-18 white--text mb-0">  {{ $ml.get('about_text2') }}</p>
                    <p v-if="integrity" class="avenir font-18 white--text mb-0">  {{ $ml.get('about_text3') }}</p>
                    <p v-if="leadership" class="avenir font-18 white--text mb-0">  {{ $ml.get('about_text4') }}</p>
                    <v-btn class="btn-text btn-header max-width-200 my-8" depressed @click="$goTo('/contactUs', 'contact')"> 
                      {{ $ml.get('button_apply') }} 
                      <v-img :src="right_arrow" :lazy-src="right_arrow" style="width:18px; cursor:pointer" class="ml-2" ></v-img>
                    </v-btn>
                    <v-layout justify-space-between>
                      <p v-bind:class="[drive ? 'selected' : 'white--text']" @click="selectOption(1)"  class="venera-bold uppercase font-12 clickable"> {{ $ml.get('about_drive') }} </p>
                      <p v-bind:class="[innovation ? 'selected' : 'white--text']" @click="selectOption(2)" class="venera-bold uppercase font-12 clickable"> {{ $ml.get('about_innovation') }} </p>
                      <p v-bind:class="[integrity ? 'selected' : 'white--text']" @click="selectOption(3)" class="venera-bold uppercase font-12 clickable"> {{ $ml.get('about_integrity') }} </p>
                      <p v-bind:class="[leadership ? 'selected' : 'white--text']" @click="selectOption(4)" class="venera-bold uppercase font-12 clickable"> {{ $ml.get('about_leadership') }} </p>
                    </v-layout>
                  </v-flex>
              </v-layout>
            </div>

            <!-- Header mobile -->

            <v-layout v-if="$vuetify.breakpoint.xsOnly">
                <v-layout column ma-8>
                 <p class="antenna font-18 secondary-color mb-12">{{$ml.get('header_menu_about')}}</p>
                 <div style="text-align:center">
                    <p v-bind:class="[drive2 ? 'selected' : 'white--text']" @click="drive2 = !drive2"  class="venera-bold uppercase font-15 clickable underline mb-8"> {{ $ml.get('about_drive') }} </p>
                    <p v-bind:class="[innovation ? 'selected' : 'white--text']" @click="innovation = !innovation" class="venera-bold uppercase font-15 clickable underline mb-8"> {{ $ml.get('about_innovation') }} </p>
                    <p v-bind:class="[integrity ? 'selected' : 'white--text']" @click="integrity = !integrity" class="venera-bold uppercase font-15 clickable underline mb-8"> {{ $ml.get('about_integrity') }} </p>
                    <p v-bind:class="[leadership ? 'selected' : 'white--text']" @click="leadership = !leadership" class="venera-bold uppercase font-15 clickable underline mb-8"> {{ $ml.get('about_leadership') }} </p>
                    <v-btn class="btn-text btn-header max-width-200 my-8" depressed @click="$goTo('/contactUs', 'contact')"> 
                      {{ $ml.get('button_apply') }} 
                      <v-img :src="right_arrow" :lazy-src="right_arrow" style="width:18px; cursor:pointer" class="ml-2" ></v-img>
                    </v-btn>
                 </div>
              </v-layout>

              <div style="position:absolute">
                  <Animated enter="slideInLeft" leave="slideOutLeft" duration="500">
                    <v-layout row wrap class="primary-background" v-show="drive2" style="min-height:300px">
                        <div class="ma-12">
                          <div style="display: flex; justify-content: space-between;">
                            <div><h3 class="venera-bold font-30 uppercase white--text" >{{ $ml.get('about_title1') }}</h3></div>
                            <div><v-img :src="left_arrow" :lazy-src="left_arrow" @click="drive2 = !drive2" style="width:30px; cursor:pointer"></v-img></div>
                          </div>
                            <p class="avenir font-16 white--text mb-0">  {{ $ml.get('about_text1') }}</p>                                  
                        </div>
                    </v-layout>
                  </Animated>
                  <Animated enter="slideInLeft" leave="slideOutLeft" duration="500">
                    <v-layout row wrap class="primary-background" v-show="innovation" style="height:auto">
                        <div class="ma-12">
                          <div style="display: flex; justify-content: space-between;">
                            <div><h3 class="venera-bold font-30 uppercase white--text" >{{ $ml.get('about_title2') }}</h3></div>
                            <div><v-img :src="left_arrow" :lazy-src="left_arrow" @click="innovation = !innovation" style="width:30px; cursor:pointer"></v-img></div>
                          </div>
                            <p class="avenir font-16 white--text mb-0">  {{ $ml.get('about_text2') }}</p>                                  
                        </div>
                    </v-layout>
                  </Animated>
                  <Animated enter="slideInLeft" leave="slideOutLeft" duration="500">
                    <v-layout row wrap class="primary-background" v-show="integrity" style="min-height:300px">
                        <div class="ma-12">
                          <div style="display: flex; justify-content: space-between;">
                            <div><h3 class="venera-bold font-17 uppercase white--text" >{{ $ml.get('about_title3') }}</h3></div>
                            <div><v-img :src="left_arrow" :lazy-src="left_arrow" @click="integrity = !integrity" style="width:30px; cursor:pointer"></v-img></div>
                          </div>
                            <p class="avenir font-16 white--text mb-0">  {{ $ml.get('about_text3') }}</p>                                  
                        </div>
                    </v-layout>
                  </Animated>
                  <Animated enter="slideInLeft" leave="slideOutLeft" duration="500">
                    <v-layout row wrap class="primary-background" v-show="leadership" style="height:auto">
                        <div class="ma-12">
                          <div style="display: flex; justify-content: space-between;">
                            <div><h3 class="venera-bold font-30 uppercase white--text" >{{ $ml.get('about_title4') }}</h3></div>
                            <div><v-img :src="left_arrow" :lazy-src="left_arrow" @click="leadership = !leadership" style="width:30px; cursor:pointer"></v-img></div>
                          </div>
                            <p class="avenir font-16 white--text mb-0">  {{ $ml.get('about_text4') }}</p>                                  
                        </div>
                    </v-layout>
                  </Animated>
                </div>
            </v-layout>

          </v-layout>


      <!-- Mission & Vision -->

  
            <v-layout row wrap px-6 ma-2 style="position:relative;" id="mission_vision" v-if="$vuetify.breakpoint.smAndUp">
              <div class="max-width-1200 ma-auto">
                <v-layout row wrap px-6 style="position:relative;" id="mission_vision" :class="{'ma-12': $vuetify.breakpoint.mdAndUp}">
                <v-flex xs12 sm6 md6 class="pa-6" @click="show_mission">
                    <div class="image-container hover">
                      <img class="blue-overlay" :src="mission" alt="Snow" style="width:100%; height:250px;" >
                      <div class="centered"><p class="venera-bold font-30 white--text">{{ $ml.get('about_mission') }}</p></div>
                    </div>
                </v-flex>

                <v-flex xs12 sm6 class="pa-6" @click="show_vision">
                  <div class="image-container hover">
                      <img class="blue-overlay" :src="vision" alt="Snow" style="width:100%; height:250px;">
                      <div class="centered"><p class="venera-bold font-30 white--text">{{ $ml.get('about_vision') }}</p></div>
                    </div>
                </v-flex>


                <div style="position:absolute" id="mission_box">
                    <v-layout row wrap ma-6 class="primary-background test">
                      <div style="height:250px;" class="primary-background">
                        <div class="ma-12">
                          <h3 class="venera-bold font-30 uppercase white--text" >{{ $ml.get('about_mission') }}</h3>
                          <div style="display:flex">
                            <div><p class="avenir font-16 white--text mb-0">  {{ $ml.get('about_mission_text') }}</p></div>
                            <div><v-img :src="left_arrow" :lazy-src="left_arrow" @click="hide_mission" style="width:30px; cursor:pointer" ></v-img></div>
                          </div>
                        </div>
                      </div>
                    </v-layout>
                </div>

                <div style="position:absolute" id="vision_box">
                  <Animated enter="slideInLeft" leave="slideOutLeft" duration="700">
                    <v-layout row wrap ma-6 class="primary-background">
                        <div style="height:250px;">
                          <div class="ma-12">
                            <h3 class="venera-bold font-30 uppercase white--text" >{{ $ml.get('about_vision') }} </h3>
                            <div style="display:flex">
                              <div><p class="avenir font-16 white--text mb-0">  {{ $ml.get('about_vision_text') }}</p></div>
                              <div><v-img :src="left_arrow" :lazy-src="left_arrow"  @click="hide_vision" style="width:30px; cursor:pointer"></v-img></div>
                            </div>
                          </div>
                        </div>
                      </v-layout>
                    </Animated>
                </div>
                </v-layout>
              </div>
            </v-layout>

            <!-- Mission & Vision mobile -->
            <v-layout row wrap class="centered-div" id="mission_vision" v-if="$vuetify.breakpoint.xsOnly">
              <v-flex xs12 sm8 @click="show_mission" style="margin-bottom:-17px">
                  <div class="image-container hover">
                    <img class="blue-overlay-mission" :src="mission" style="width:100%; height:290px;">
                    <div class="centered"><p class="venera-bold font-30 white--text">{{ $ml.get('about_mission') }}</p></div>
                  </div>
              </v-flex>
      
              <div style="position:absolute; margin-top:-5px;" id="mission_box">
                    <v-layout row wrap class="green-background">
                        <div class="ma-12">
                          <div style="display: flex; justify-content: space-between;">
                            <div><h3 class="venera-bold font-30 uppercase white--text" >{{ $ml.get('about_mission') }}</h3></div>
                            <div><v-img :src="left_arrow_white" :lazy-src="left_arrow_white" @click="hide_mission" style="width:30px; cursor:pointer"></v-img></div>
                          </div>
                            <p class="avenir font-16 white--text mb-0">  {{ $ml.get('about_mission_text') }}</p>                                  
                        </div>
                    </v-layout>
                </div>
            </v-layout>

            <v-layout row wrap class="centered-div" v-if="$vuetify.breakpoint.xsOnly">
              
              <v-flex xs12 sm8 @click="show_vision">
                  <div class="image-container hover">
                     <img class="blue-overlay-vision" :src="vision" style="width:100%; height:270px">
                      <div class="centered"><p class="venera-bold font-30 white--text">{{ $ml.get('about_vision') }}</p></div>
                  </div>
              </v-flex>
      
              <div style="position:absolute" id="vision_box">
                    <v-layout row wrap class="primary-background" style="height:300px">
                        <div class="ma-12">
                          <div style="display: flex; justify-content: space-between;">
                            <div><h3 class="venera-bold font-30 uppercase white--text" >{{ $ml.get('about_vision') }}</h3></div>
                            <div><v-img :src="left_arrow" :lazy-src="left_arrow" @click="hide_vision" style="width:30px; cursor:pointer"></v-img></div>
                          </div>
                            <p class="avenir font-16 white--text mb-0">  {{ $ml.get('about_vision_text') }}</p>                                  
                        </div>
                    </v-layout>
                </div>
            </v-layout>


          <!-- Expansion panel web -->



           <div class="max-width-1200 ma-auto" v-if="$vuetify.breakpoint.mdAndUp">
            <v-layout row wrap px-12 pb-4 mx-12 mb-12>
              <v-flex xs12>
                <p class="venera font-19 primary-color" id="company">{{ $ml.get('company') }}</p>
              </v-flex>
                <v-row justify="center">
                    <v-expansion-panels accordion style="z-index:auto">
                      <v-expansion-panel :key="i" elevation-0 class="clear-background">
                        <v-expansion-panel-header @click="firstArrow = !firstArrow">
                            <div class="ma-6" style="display:flex; justify-content: space-between; align-items: flex-end;">
                              <p class="antenna font-34 mb-0" :class="{'third-color': firstArrow, 'primary-color': !firstArrow}">01. {{$ml.get('about_passion')}}</p>
                              <div>
                                <img v-if="!firstArrow" :src="down_arrow" width="50px; cursor:pointer"/>
                                <img v-else-if="firstArrow" :src="up_arrow" width="50px; cursor:pointer"/>
                              </div>
                            </div>
                        </v-expansion-panel-header> 
                        <v-expansion-panel-content>
                         <v-layout row wrap class="aqua-background" my-12 mx-0 style="height:250px;">
                              <v-flex md6>
                                <img :src="passion" :lazy-src="passion" class="grey lighten-2 blue-overlay" style="height:250px;"/>
                              </v-flex>
                              <v-flex md6 style="display: flex; align-items: center;">
                                <div class="ma-6">
                                  <h2 class="antenna white--text font-28">{{$ml.get('about_passion_title')}}</h2>
                                  <p class="avenir font-17 white--text mb-0">{{$ml.get('about_passion_text')}}</p>
                                </div>
                              </v-flex>                              
                          </v-layout>                     
                        </v-expansion-panel-content>
                        <v-divider class="mt-8 divider"></v-divider>
                      </v-expansion-panel>

                      <v-expansion-panel :key="i" elevation-0 class="clear-background">
                        <v-expansion-panel-header @click="secondArrow = !secondArrow">
                            <div class="ma-6" style="display:flex; justify-content: space-between; align-items: flex-end;">
                              <p class="antenna font-34 mb-0" :class="{'third-color': secondArrow, 'primary-color': !secondArrow}">02. {{$ml.get('about_future')}}</p>
                              <div>
                                <img v-if="!secondArrow" :src="down_arrow" width="50px; cursor:pointer"/>
                                <img v-else-if="secondArrow" :src="up_arrow" width="50px; cursor:pointer"/>
                              </div>
                            </div>
                        </v-expansion-panel-header> 
                        <v-expansion-panel-content>
                          <v-layout row wrap class="aqua-background" my-12 mx-0 style="height:250px;">
                              <v-flex md6>
                                <img :src="future" :lazy-src="future" class="grey lighten-2 blue-overlay" style="height:250px;"/>
                              </v-flex>
                              <v-flex md6 style="display: flex; align-items: center;">
                                <div class="ma-6">
                                  <h2 class="antenna white--text font-28">{{$ml.get('about_future_title')}}</h2>
                                  <p class="avenir font-17 white--text mb-0">  {{$ml.get('about_future_text')}}</p>
                                </div>
                              </v-flex>                              
                          </v-layout>
                        </v-expansion-panel-content>
                        <v-divider class="mt-8 divider"></v-divider>
                      </v-expansion-panel>

                      <v-expansion-panel :key="i" elevation-0 class="clear-background">
                        <v-expansion-panel-header @click="thirdArrow = !thirdArrow">
                            <div class="ma-6" style="display:flex; justify-content: space-between; align-items: flex-end;">
                              <p class="antenna font-34  mb-0" :class="{'third-color': thirdArrow, 'primary-color': !thirdArrow}">03. {{$ml.get('about_value')}}</p>
                              <div>
                                <img v-if="!thirdArrow" :src="down_arrow" width="50px; cursor:pointer"/>
                                <img v-else-if="thirdArrow" :src="up_arrow" width="50px; cursor:pointer"/>
                              </div>
                            </div>
                        </v-expansion-panel-header> 
                       <v-expansion-panel-content>
                          <v-layout row wrap class="aqua-background" my-12 mx-0 style="height:250px;">
                              <v-flex md6>
                                <img :src="value" :lazy-src="value" class="grey lighten-2 blue-overlay" style="height:250px;"/>
                              </v-flex>
                              <v-flex md6 style="display: flex; align-items: center;">
                                <div class="ma-6">
                                  <h2 class="antenna white--text font-28">{{$ml.get('about_value_title')}}</h2>
                                  <p class="avenir font-17 white--text mb-0">{{$ml.get('about_value_text')}}</p>
                                </div>
                              </v-flex>                              
                          </v-layout>
                        </v-expansion-panel-content>
                        <v-divider class="mt-8 divider mb-8"></v-divider>
                      </v-expansion-panel>

                      
                    </v-expansion-panels>
                  </v-row>
            </v-layout>
           </div>

            <!-- Expansion panel mobile -->
            
            <v-layout row wrap ma-3 pa-6 id="company" v-if="$vuetify.breakpoint.smAndDown" :class="{'mx-12': $vuetify.breakpoint.smAndUp}"> 
                <div class="max-width-1200 ma-auto">
                    <p class="venera font-19 primary-color mb-0 mt-12">{{ $ml.get('company') }}</p>
                    <v-layout row wrap>   
                        <v-flex md4>
                            <v-divider class="my-6 divider mr-0"></v-divider>
                            <img class="blue-overlay" :src="passion" style="width:100%; height:250px;">
                                <h2 class="antenna font-23 primary-color my-2">01. {{$ml.get('about_passion')}}</h2>
                                <p class="avenir primary-color font-16 mb-0">{{$ml.get('about_passion_text')}}</p>
                        </v-flex>

                       <v-flex md4>
                            <v-divider class="my-6 divider"></v-divider>
                            <img class="blue-overlay" :src="future" style="width:100%; height:250px;">
                               <h2 class="antenna font-23 primary-color my-2">02. {{$ml.get('about_future')}}</h2>
                                <p class="avenir primary-color font-16 mb-0">{{$ml.get('about_future_text')}}</p>
                        </v-flex>

                        <v-flex md4>
                            <v-divider class="my-6 divider"></v-divider>
                            <img class="blue-overlay" :src="value" style="width:100%; height:250px;">
                                <h2 class="antenna font-23 primary-color my-2">03. {{$ml.get('about_value')}}</h2>
                                <p class="avenir primary-color font-16 mb-0">{{$ml.get('about_value_text')}}</p>
                                <v-divider class="my-6 divider"></v-divider>
                        </v-flex>
                    </v-layout>
                </div>
            </v-layout>


       


      <!-- World map -->
      
      <div class="primary-background">
        <div class="max-width-1200 ma-auto">
        <v-layout row wrap pt-6  :class="{'px-12': $vuetify.breakpoint.smAndUp}" id="offices">
          <v-flex xs12 mt-12 mx-12>
            <p class="venera font-19 white--text">{{ $ml.get('offices') }}</p>
          </v-flex>
              <v-layout row wrap mx-12>
                <v-flex md7 class="mt-4">
                      <p class="antenna font-37 white--text mb-0 line-height-38 mb-3"> {{ $ml.get('about_map_title') }}</p>
                      <p class="font-18 white--text mb-0" style="width:85%">  {{ $ml.get('about_map_text') }}</p>
                </v-flex>
                <v-flex md5 class="mt-8">
                  <v-layout>
                    <v-flex style="display: flex; justify-content: flex-end; align-items: baseline;">
                        <button class="point project mr-2"></button>
                        <p class="antenna font-16 white--text">{{ $ml.get('about_projects') }}</p>
                    </v-flex>
                  </v-layout>
                  <v-layout column wrap>
                      <v-flex style="display: flex; justify-content: flex-end; align-items: baseline;" mr-2>
                        <button class="point office mr-2"></button>
                        <p class="antenna font-16 white--text">{{ $ml.get('about_offices') }}</p>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
                      

            <v-layout row wrap justify-center mb-12 :class="{'map-layout': $vuetify.breakpoint.xsOnly}">
            <v-flex sm3 style="display: flex; align-items: flex-end;" class="pa-4">
              <div class="mx-12 px-6">
                  <v-layout row wrap>
                      <p class="venera-bold font-26 white--text uppercase">{{$ml.get('about_countries')}}:</p>
                    <v-flex row wrap>
                      <p class="antenna font-14 white--text mx-2 uppercase">{{$ml.get('mexico')}}</p>
                      <p class="antenna font-14 white--text mx-2 uppercase">{{$ml.get('brasil')}}</p>
                      <p class="antenna font-14 white--text mx-2 uppercase">{{$ml.get('spain')}}</p>
                      <p class="antenna font-14 white--text mx-2 uppercase">{{$ml.get('canada')}}</p>
                      <p class="antenna font-14 white--text mx-2 uppercase">{{$ml.get('nigeria')}}</p>
                      <p class="antenna font-14 white--text mx-2 uppercase">{{$ml.get('usa')}}</p>
                      <p class="antenna font-14 white--text mx-2 uppercase">{{$ml.get('panama')}}</p>
                    </v-flex>
                  </v-layout>
              </div>
            </v-flex>
            <v-flex sm9 mb-12>
                    <div class="is-hidden-mobile mb-12 mx-4" style="position:relative">
                    <section class="distribution-map">
                    <img class="change-my-color" src="../assets/img/images/world-map1.svg">
                    <button class="map-point project" style="top:40%;left:16%"/>
                    <button class="map-point office" style="top:57.5%;left:19.4%"/>
                    <button class="map-point office" style="top:80%;left:33.1%"/>
                    <button class="map-point project" style="top:51%;left:45%"/>
                    <button class="map-point project" style="top:67%;left:50%"/>
                    <button class="map-point project" style="top:50%;left:20%"/>
                    <button class="map-point project" style="top:64%;left:25%"/>

                    </section>
                  </div>
                <!-- 
                  <div class="is-hidden-tablet">
                    <section class="distribution-map" >
                      <img class="change-my-color" src="../assets/img/images/world-map.svg">
                      <div class="map-point project mobile" style="top:46%;left:18%"/>
                      <div class="map-point office mobile" style="top:54.5%;left:21%"/>
                      <div class="map-point office mobile" style="top:75%;left:33.1%"/>
                      <div class="map-point project mobile" style="top:51%;left:45%"/>
                      <div class="map-point project mobile" style="top:63%;left:49%"/>
                      <div class="map-point project mobile" style="top:54%;left:76%"/>
                      </section>
                  </div> -->
            </v-flex>
          </v-layout>
          </v-layout>
      </div>
      </div>


    <!-- Team -->

      <div class="white-background">
        <div class="max-width-1200 ma-auto">
          <v-layout row wrap pt-6 mx-8 :class="{'px-12': $vuetify.breakpoint.smAndUp}" id="team">
            <v-flex xs12 sm4 mt-12>
              <p class="venera font-19 primary-color mb-0">  {{ $ml.get('about_team_intro') }}</p>
              <p class="antenna font-28 primary-color line-height-38"> {{ $ml.get('about_team_title') }} </p>

            </v-flex>
            <v-flex sm8></v-flex>
            <v-flex sm6></v-flex>
            <v-flex sm6>
                <p class="antenna font-23 primary-color mb-0"> {{ $ml.get('about_team_subtitle') }} </p>
                    <p class="avenir font-15 primary-color">  {{ $ml.get('about_team_text') }}</p>
                    <v-btn class="btn-text-white btn-secondary max-width-200 mb-8" depressed @click="$goTo('/contactUs', 'contact')"> 
                      {{ $ml.get('button_work') }} 
                      <v-img :src="right_arrow_white" :lazy-src="right_arrow_white" style="width:18px; cursor:pointer" class="ml-2" ></v-img>
                    </v-btn>
            </v-flex>
        </v-layout>

        <v-layout mt-8>
          <v-flex xs12 sm10 offset-sm1>
              <v-container fluid>
                <v-layout wrap>
                  <v-flex v-for="(user, i) in users" :key="i" xs6 sm4 child-flex>
                    <v-hover>
                      <div slot-scope="{ hover }" >
                        <v-img :src="user.image" :lazy-src="user.image" aspect-ratio="1" class="grey lighten-2" :class="{'mobile-transition': $vuetify.breakpoint.xsOnly}">
                          <v-expand-transition>
                            <v-flex
                              v-if="hover"
                              class=" transition-fast-in-fast-out hover-img darken-2 v-card--reveal transition-height"
                              style="display:flex"
                            >
                            <v-layout row wrap :class="{'pa-12': $vuetify.breakpoint.mdAndUp}" justify-center>
                                <v-flex xs12 style="display: flex; justify-content: center;">
                                  <h3 class="antenna my-1 white--text mt-2" :class="{'font-18': $vuetify.breakpoint.mdAndUp, 'font-12': $vuetify.breakpoint.smAndDown}">{{user.name}}</h3>
                                </v-flex>
                                <v-flex xs12 style="display: flex; justify-content: center; text-align:center">
                                  <p class="venera-bold secondary-color" :class="{'font-14': $vuetify.breakpoint.mdAndUp, 'font-12': $vuetify.breakpoint.smAndDown}">{{user.area}}</p>
                                </v-flex>                        
                      
                            </v-layout>
                            </v-flex>
                          </v-expand-transition>
                              <template v-slot:placeholder>
                                <v-layout fill-height align-center justify-center ma-0>
                                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                </v-layout>
                              </template>
                            </v-img>
                        </div>
                    </v-hover>
                  </v-flex>
                </v-layout>
              </v-container>
          </v-flex>
        </v-layout>

        <v-layout row wrap style="display: flex; justify-content: center;">
          <v-flex xs8 sm3 my-12>
            <p class="antenna font-22 primary-color text-center"> {{ $ml.get('about_apply_title') }} </p>
            <p class="avenir font-16 primary-color text-center">  {{ $ml.get('about_apply_text') }}</p>
            <v-layout justify-center align-center>
              <v-btn class="btn-text-white btn-secondary max-width-200 my-2" depressed @click="$goTo('/contactUs', 'contact')"> 
                {{ $ml.get('button_apply') }} 
                <v-img :src="right_arrow_white" :lazy-src="right_arrow_white" style="width:18px; cursor:pointer" class="ml-2" ></v-img>
              </v-btn>
            </v-layout>

          </v-flex>
        </v-layout>
      </div>
      </div>



    

  </v-container>
</template>
<script>
export default {
  data () {
    return {
      name: '',
      mission_text: false,
      vision_text: false,
      passion_text: false,
      future_text: false,
      drive: true,
      drive2: false,
      innovation: false,
      integrity: false,
      leadership: false,
      firstArrow: false,
      secondArrow: false,
      thirdArrow: false,
      users: null,
      team: {
        eduardo: require('../assets/img/team/EduardoG.png'),
        mayorga: require('../assets/img/team/Diego_BYN.png'),
        erick: require('../assets/img/team/Erick_BYN.png'),
        juan: require('../assets/img/team/JuanPaulo.png'),
        arturo: require('../assets/img/team/Arturo.jpg'),
        luis: require('../assets/img/team/LuisAngel.jpg'),
        sergio: require('../assets/img/team/Sergio_BYN.png'),
        dago: require('../assets/img/team/Dago.jpg'),
        roberto: require('../assets/img/team/Roberto_BYN.png'),
      },
      mission: require('../assets/img/images/mission.png'),
      vision: require('../assets/img/images/vision.png'),
      left_arrow: require('../assets/img/brand/driveop_arrow_left.png'),
      down_arrow: require('../assets/img/brand/driveop_arrow_down.png'),
      up_arrow: require('../assets/img/brand/driveop_arrow_up.png'),
      right_arrow: require('../assets/img/brand/driveop_arrow_right.png'),
      right_arrow_white: require('../assets/img/brand/driveop_logo_white.png'),
      left_arrow_white: require('../assets/img/brand/driveop_arrow_white_left.png'),
      passion: require('../assets/img/images/our-passion-drives-us.png'),
      future: require('../assets/img/images/we-strive-for-the-future.png'),
      value: require('../assets/img/images/we-increase-value.png'),
      building: require('../assets/img/images/edificios.svg'),
    }
  },
  methods : {
    show_mission () {
      var element = document.getElementById("mission_box");
		  element.classList.add("move");
    },
    hide_mission () {
      var element = document.getElementById("mission_box");
		  element.classList.remove("move");
    },
    show_vision () {
      var element = document.getElementById("vision_box");
		  element.classList.add("move");
    },
    hide_vision () {
      var element = document.getElementById("vision_box");
		  element.classList.remove("move");
    },
    getUsers () {
      this.$api.getUsers(
        response => {
          this.users = response.body.data
        },
        response => this.$errorHandling(response).then(() => {
          this.getUsers()
        }, () => {})
      )
    },
    selectOption (option) {
        if (option == 1) {
          this.drive = true
          this.innovation = false
          this.integrity = false
          this.leadership = false
          
        }
        else if (option == 2) {
          this.drive = false
          this.innovation = true
          this.integrity = false
          this.leadership = false
          
        }
        else if (option == 3) {
          this.drive = false
          this.innovation = false
          this.integrity = true
          this.leadership = false
          
        }
        else if (option == 4) {
          this.drive = false
          this.innovation = false
          this.integrity = false
          this.leadership = true
          
        }
    },
  },
  mounted () {
    this.getUsers()
  }
}
</script>

<style scoped>
.v-card__title {
  justify-content: center;
}
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color:#028b8f !important; 
}


.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image-container {
  position: relative;
  text-align: center;
  color: white;
}

.point {
    outline: none;
    z-index: 0;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: rgba(26, 26, 26, 0.85);
    border: 1px solid rgba(26, 26, 26, 0.85);
}

 .office {
  background: #10A2A4 !important;
  border: 1px solid #10A2A4 !important;
}

.selected {
  color: #0bd1cb;
}

 .project {
  background: #ffffff !important;
  border: #ffffff !important;
}

.centered-div {
  display:flex;
  justify-content: center;
}

.map-layout {
  flex-direction: column-reverse
}

.mobile-transition {
  display: flex;
  align-items: flex-end;
}

.transition-height {
  height: 100%;
}

.hover-img {
  background-color: rgb(26,60,79);
  opacity: 0.83;
  font-size: 28px;
  color: #454545;
  align-items: center;
}

.blue-overlay {
    outline: 125px solid rgba(26,60,79,0.4) !important;
    outline-offset: -125px !important;
    overflow: hidden !important;
    position: relative !important;
    width: 100% !important;
}
.blue-overlay-mission {
  outline: 145px solid rgba(26,60,79,0.4) !important;
    outline-offset: -145px !important;
    overflow: hidden !important;
    position: relative !important;
    width: 100% !important;
}

.blue-overlay-vision {
  outline: 135px solid rgba(26,60,79,0.4) !important;
    outline-offset: -135px !important;
    overflow: hidden !important;
    position: relative !important;
}
#mission_box {	
  transition: 0.5s !important;
  transform: translate3d(-155%, 0px, 0px);
}
#vision_box {	
  transition: 0.5s !important;
  transform: translate3d(-155%, 0px, 0px);
}
#mission_box_mobile {	
  transition: 0.5s !important;
  transform: translate3d(-155%, 0px, 0px);
}
#vision_box_mobile {	
  transition: 0.5s !important;
  transform: translate3d(-155%, 0px, 0px);
}
.move {
  transform: translate3d(0%, 0px, 0px) !important;		
}

@media screen and (max-width: 600px) {
  .transition-height {
  height: 50%;
}
.hover-img {
  font-size: 14px;
}

}
</style>
